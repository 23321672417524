import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../../../../components/mdx/page";
import { Breadcrumbs } from "../../../../../components/widgets/breadcrumbs";
import { Aside } from "../../../../../components/widgets/aside";
import { Border } from "../../../../../components/widgets/container";
import { ButtonPrimary, ButtonSecondary } from "../../../../../components/widgets/button";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{props.pageContext.frontmatter.title}</h1>
    <Breadcrumbs to="/company/newsletter/" text="Newsletter Archive" mdxType="Breadcrumbs" /> 
    <p>{`Hello `}<em parentName="p">{`TuringTrader`}</em>{` Member,`}</p>
    <p>{`November is here, and while the year slowly winds down, we are working down our busy roadmap of making further improvements to the TuringTrader site. But before going into that, let's talk strategy, shall we?`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.turingtrader.com/portfolios/freeburg-pentad/?utm_source=tt-com&utm_medium=email&utm_campaign=newsletter-november-2023"
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACZElEQVQoz22Ty09TQRjFR2lUhLhhS6Im/gEuXLgzLnTrxqVBkNoWNCyMhoTEYKKJIKCissONuhIjJiYseAUJT3lpgZbetoiVl0AplN7e9/2Ze32A1ZOcZHLmmzPzZb4jktsZlpZXkOUMlmVhmuZfdDXLxrBsdMPEMEzAxsGOYjD2JUPV6wXOPY4gKxrCMAz2wrbBtHZp2za5GPsqc/v9MqcfSIiKKcTlTxTdmGYroyJ0Xf9t5b7kfxiMZnnYmeTS8wRnmyPkVwURJVOIsmk8vhCiPMSx6ihrm+mfho6PZlju4ZRs0T6ZpiuU4dXIFmeaYngqpxFl4wjvOKL0M8Ib4oA/jMcfcimuzHC0WmJ1PYUw97ScUS3OP4kjSkYR5ROI0klE2STCH8QTmCHPF3K5zzf7h/t9s65h8a05tp2WZVVjbCFLY+cGJ+9GEd4gR65HKKyUKLw2R0EgxkGfREEgyuGARL4/yiG/5GqeqxFXyyuXOFEjkZY1RCor0zQY506PRMtYnIahKPUDEg19Ceq6lrk/INE8PE9tb4R7H6LUDYVpHIzxaCRG63iC+v64W/OsZwNZ0RGKrtL/bY2ZzQ2i8nda54IUt7xD+HopuhnkZShGx/wiJe0TXHjTz/GmPtrCCYZT83QnFrnYNoq34yOnno6wsp7+d2ywoKZ9lYoXa3SH5JzfVqh9u4mp72pLSZvwksHI+hKrySRCVVVkRUHOZskqDhV2ZAXLVMHWXH1Hzrh7m+ksmazi1jhnFFVB1xRMQ8XWdDRNQziDm5sOh04qNN1w186l7nhZJlZOnfErPbphuCH4AWBU34N4e9UXAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "pentad equity dd",
            "title": "pentad equity dd",
            "src": "/static/231505b7d20e0f03c08ea42e3804e0ed/c1b63/pentad-equity-dd.png",
            "srcSet": ["/static/231505b7d20e0f03c08ea42e3804e0ed/5a46d/pentad-equity-dd.png 300w", "/static/231505b7d20e0f03c08ea42e3804e0ed/0a47e/pentad-equity-dd.png 600w", "/static/231505b7d20e0f03c08ea42e3804e0ed/c1b63/pentad-equity-dd.png 1200w", "/static/231505b7d20e0f03c08ea42e3804e0ed/21b4d/pentad-equity-dd.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></a></p>
    <h2>{`Nelson Freeburg's PENTAD`}</h2>
    <p>{`A couple of days ago, a friend asked me what I thought of the markets and why the past two years have been so difficult. I indeed believe that these years have been unusual, both with the violence of stocks and bonds declining in unison, and regarding the lack of direction. Of course, this makes one wonder if there was a better way. How about `}<a parentName="p" {...{
        "href": "https://www.turingtrader.com/dashboard/market-vane/?utm_source=tt-com&utm_medium=email&utm_campaign=newsletter-november-2023"
      }}>{`Market Vane`}</a>{` and the basic risk-on/ risk-off decision?`}</p>
    <p>{`I passionately believe that good trading models should include macroeconomic cues, instead of solely relying on price action of the asset at hand. That's what `}<a parentName="p" {...{
        "href": "https://www.turingtrader.com/dashboard/market-vane/?utm_source=tt-com&utm_medium=email&utm_campaign=newsletter-november-2023"
      }}>{`Market Vane`}</a>{` does and that's why I love testing models designed in the 80s and 90s. During that time, researchers did not have the high-powered PCs we have today. So, the models needed to be much simpler, but typically contained smart macroeconomic ideas.`}</p>
    <p>{`One of the heroes of that time was `}<em parentName="p">{`Nelson Freeburg`}</em>{`, a respected and influential researcher who developed many timing models for stock, bond, and commodity markets. Between 1991 and 2014, he published the `}<em parentName="p">{`Formula Research`}</em>{` newsletter, which was widely appreciated by professional money managers and traders. If you `}<a parentName="p" {...{
        "href": "https://www.google.com/search?q=nelson+freeburg+formula+research"
      }}>{`search the internet`}</a>{`, you will find many of the fabulous articles he wrote - and they all make a fascinating read.`}</p>
    <p>{`The model I wanted to check out was `}<a parentName="p" {...{
        "href": "https://www.turingtrader.com/portfolios/freeburg-pentad/?utm_source=tt-com&utm_medium=email&utm_campaign=newsletter-november-2023"
      }}>{`PENTAD`}</a>{`, a strategy switching its exposure between stocks and T-bills. As the name suggests, the model looks at five inputs. The S&P 500 index (of course), but also market breath, the transportation and utilities sector, and the bond market. You can find a more complete description of the rules on the strategy's page. For now, it may suffice to say that the model relies on moving average slopes and crossovers, with some added hysteresis to avoid whipsaws. The way the various indicators work together is similar to `}<a parentName="p" {...{
        "href": "https://www.turingtrader.com/portfolios/heine-bond-model/?utm_source=tt-com&utm_medium=email&utm_campaign=newsletter-november-2023"
      }}>{`Heine's Bond Trading Model`}</a>{`, which stems from the same era.`}</p>
    <p>{`The model passed the test of time with flying colors. Especially in 2022, and 2023, it got things mostly right. And while that is encouraging, we can't ignore its flaws either. Many of `}<a parentName="p" {...{
        "href": "https://www.turingtrader.com/portfolios/freeburg-pentad/?utm_source=tt-com&utm_medium=email&utm_campaign=newsletter-november-2023"
      }}>{`PENTAD`}</a>{`'s indicators are too slow to follow fast-paced turnarounds like those seen in October 2018 and March 2020. Consequently, the strategy takes a double whammy here: First, it incurs losses from the sell-off, and when it finally gets out, it misses the rebound.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.turingtrader.com/portfolios/freeburg-pentad/?utm_source=tt-com&utm_medium=email&utm_campaign=newsletter-november-2023"
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB/ElEQVQoz5WRP2gTARTGX/1THR1cRBzETahOTg6Ci3vBNKEObTUNtlrBaimoUHUQLKLikkUHqRqjgjoItm4OaqHGXJKmNWloJSk2aXKpzeVyd737Sa8atYitH/x48D54vPc9GXg+y5YuhR19MfZfmaA3nCP0QeV9pko6b5D/ZrGo2zgOa8imputI7+Mc0hKjIRBHOqJI+yekPYIEomw/F2f3xSR7ByY5cC3FoRtTHLmdoTk4Teu9L/iHspwO5TgTznF9OE9hvoT0Pckhx2JsPZlkcyDJxs5x5EQCaYsjrTHEpyC+COKNIC0fEc8qlnvNYzR2K8zmS8jZUA7xKIg/gRxXaOyKs6s/yb6rkxy+mcZ3d5pToSyXXs4yODJH8G2BodEiTyMlXigqrxJlhpNl3mUWKZcXkFsjczQNTHFwMEXb/WkejhVIFzUqpunmsj6WA17CMAzk/LMZtvV8Zs+FCXb2J2i6nELVTRyW0CzTpWpZ/8Cs10qlggQeZBBvlE3dUcSr4AlmV35m898qlUpIz6MZpCPChs6E+5hYVnfNJdvBcdZm5dwVqaqK3HmTR44qNPjHCY0u1IetV7bjoFVrbn7uhoWyRl84y+uYCljoNcM1f6LruptNtVr9o1/3awZf8/MUi0UM00R+X/lvZ1qWhaZp7uDVnm3bP87+pe9Sd+3AvhnT2QAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "pentad monte carlo",
            "title": "pentad monte carlo",
            "src": "/static/5afb787e5b892da9e8c72b965179acfb/c1b63/pentad-monte-carlo.png",
            "srcSet": ["/static/5afb787e5b892da9e8c72b965179acfb/5a46d/pentad-monte-carlo.png 300w", "/static/5afb787e5b892da9e8c72b965179acfb/0a47e/pentad-monte-carlo.png 600w", "/static/5afb787e5b892da9e8c72b965179acfb/c1b63/pentad-monte-carlo.png 1200w", "/static/5afb787e5b892da9e8c72b965179acfb/21b4d/pentad-monte-carlo.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></a></p>
    <p>{`It is interesting to see how `}<a parentName="p" {...{
        "href": "https://www.turingtrader.com/portfolios/freeburg-pentad/?utm_source=tt-com&utm_medium=email&utm_campaign=newsletter-november-2023"
      }}>{`PENTAD`}</a>{` stacks up versus `}<a parentName="p" {...{
        "href": "https://www.turingtrader.com/portfolios/tt-easy-peasy/?utm_source=tt-com&utm_medium=email&utm_campaign=newsletter-november-2023"
      }}>{`Easy Peasy`}</a>{`. Even though `}<a parentName="p" {...{
        "href": "https://www.turingtrader.com/portfolios/freeburg-pentad/?utm_source=tt-com&utm_medium=email&utm_campaign=newsletter-november-2023"
      }}>{`PENTAD`}</a>{` has slightly higher average returns, when it comes to dependable returns at the 5th percentile, the Monte Carlo chart shows that it takes 20 years until `}<a parentName="p" {...{
        "href": "https://www.turingtrader.com/portfolios/freeburg-pentad/?utm_source=tt-com&utm_medium=email&utm_campaign=newsletter-november-2023"
      }}>{`PENTAD`}</a>{` catches up with `}<a parentName="p" {...{
        "href": "https://www.turingtrader.com/portfolios/tt-easy-peasy/?utm_source=tt-com&utm_medium=email&utm_campaign=newsletter-november-2023"
      }}>{`Easy Peasy`}</a>{`. In the meantime, `}<a parentName="p" {...{
        "href": "https://www.turingtrader.com/portfolios/tt-easy-peasy/?utm_source=tt-com&utm_medium=email&utm_campaign=newsletter-november-2023"
      }}>{`Easy Peasy`}</a>{` pleases its investors with significantly lower volatility and drawdowns.`}</p>
    <p>{`To circle back to my original thought about investing in the current markets: We all wish for a smoother ride. But a model like `}<a parentName="p" {...{
        "href": "https://www.turingtrader.com/portfolios/freeburg-pentad/?utm_source=tt-com&utm_medium=email&utm_campaign=newsletter-november-2023"
      }}>{`PENTAD`}</a>{` cannot dethrone `}<a parentName="p" {...{
        "href": "https://www.turingtrader.com/dashboard/market-vane/?utm_source=tt-com&utm_medium=email&utm_campaign=newsletter-november-2023"
      }}>{`Market Vane`}</a>{` as a market regime filter. In the years since 1995 (when `}<a parentName="p" {...{
        "href": "https://www.turingtrader.com/portfolios/freeburg-pentad/?utm_source=tt-com&utm_medium=email&utm_campaign=newsletter-november-2023"
      }}>{`PENTAD`}</a>{` was first released), the markets have become much faster, and these old models can't keep up with that.`}</p>
    <p>{`However, the fundamental ideas behind these old models are still valid. That's why I keep experimenting with ways to resurrect these ideas, leveraging the capabilities modern PCs and backtesting engines offer. In the meantime, `}<a parentName="p" {...{
        "href": "https://www.turingtrader.com/portfolios/freeburg-pentad/?utm_source=tt-com&utm_medium=email&utm_campaign=newsletter-november-2023"
      }}>{`PENTAD`}</a>{` makes an excellent choice for our valued members on the `}<a parentName="p" {...{
        "href": "https://www.turingtrader.com/sign-up/"
      }}>{`free Basic plan`}</a>{`.`}</p>
    <h2>{`Membership Management`}</h2>
    <p>{`A project like `}<em parentName="p">{`TuringTrader`}</em>{` has many different aspects. First, there is the `}<a parentName="p" {...{
        "href": "https://www.turingtrader.org/"
      }}>{`backtesting engine`}</a>{`, and of course the range of portfolios we have on the site. Then, there is a web frontend, which includes many custom widgets to render the various charts and tables. Less obvious is the backend, which includes the processes to update the portfolios, handle authentication, process payments, and provide cloud storage.`}</p>
    <p>{`Small companies like ours need to pick their battles wisely, focus on what they do best, and outsource complexity where they can. That's why we decided early on that we should not build the infrastructure for authentication and billing but buy that functionality as a service instead. Replacing such a module is painful, but I am happy to report that our transition on October 14-15 worked out with only minor hiccups.`}</p>
    <p>{`Now that the dust has settled, we have line of sight of the road ahead again. `}<a parentName="p" {...{
        "href": "https://www.outseta.com/"
      }}>{`Our new platform`}</a>{` goes far beyond authentication and billing, but also integrates CRM, help desk, and emails. This allows us to greatly improve our communication including the monthly newsletter, the rebalancing notifications, and reacting to messages you send us via the contact form. We are anxiously awaiting further improvements in the coming months.`}</p>
    <p>{`As always, I want to thank everybody who reached out over the past month with questions, comments, and suggestions. It is an honor to have you on our site. If you like what we do, support our growth by `}<a parentName="p" {...{
        "href": "https://www.turingtrader.com/company/referral-program/?utm_source=tt-com&utm_medium=email&utm_campaign=newsletter-november-2023"
      }}>{`sharing with your friends`}</a>{` how TuringTrader adds value for your investing.`}</p>
    <p>{`Stay tuned for further updates,`}<br />{`
All the best`}<br /></p>
    <br />
Felix<br />
 --<br />
Felix Bertram<br />
Founder of TuringTrader.com

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      